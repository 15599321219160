import React, { useEffect, useState } from "react";
import type { ElviaTopThree } from "../../functions/api/usageTopThree";
import axios from "axios";
import clsx from "clsx";
import { formatPercent } from "../utils/numbers";

export default function Toppene({ dummyData }: { dummyData?: ElviaTopThree }): JSX.Element {
  const [topThree, setTopThree] = useState<ElviaTopThree | null>(dummyData ?? null);
  const [loading, setLoading] = useState(!dummyData);

  if (!dummyData) {
    useEffect(() => {
      void axios.get("/api/usageTopThree").then(res => {
        setTopThree(res.data as ElviaTopThree);
        setLoading(false);
      });
    }, []);
  }

  return loading ?
    <>
      <h1>Laster...</h1>
    </> :
    <>
      <div className="my-20 w-full">
        <h1 className="my-6 text-center text-7xl py-auto">Toppene</h1>
        <div className="grid grid-cols-1 grid-rows-3 sm:grid-cols-2 sm:grid-rows-2 md:grid-cols-3 md:grid-rows-1 gap-6">
          {topThree?.currentMonth.map((data, i) => <div className={clsx("rounded-lg bg-gray-800 shadow-2xl py-auto", i === 2 && "sm:col-span-2 md:col-span-1")} key={i}>
            <h2 className="my-3 text-center text-4xl">{3 - i}. høyeste</h2>
            <div className="grid grid-cols-2 grid-rows-2 gap-y-5">
              <div className="col-span-2 mx-2 my-1">
                <p className="text-center text-lg">{["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"][new Date().getMonth()]}</p>
                <p className="mx-auto w-max text-5xl">{data.value} {data.unit}</p>
              </div>
              <div className="mx-2 my-1">
                <p className="text-center text-lg">Forrige måned</p>
                <p className="text-center text-3xl">{topThree.lastMonth[i]!.value} {topThree.lastMonth[i]!.unit}</p>
              </div>
              <div className="mx-2 my-1">
                <p className="text-center text-lg">Forskjell</p>
                <p className="text-center text-3xl">{formatPercent(data.value / topThree.lastMonth[i]!.value - 1)}</p>
              </div>
            </div>
          </div>).reverse()}
        </div>
      </div>
    </>;
}
