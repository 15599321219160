import { Outlet } from "react-router-dom";
import React from "react";

export default function Layout(): JSX.Element {
  return (
    <div className="flex min-h-screen bg-gray-900 text-white">
      <div className="m-auto max-w-7xl py-4 px-6 w-screen">
        <Outlet />
      </div>
    </div>
  );
}
