/* eslint-disable id-length */
import React, { useEffect, useState } from "react";
import type { ElviaUsage } from "../../functions/api/usage";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import type { VGCost } from "../../functions/api/cost";
import axios from "axios";

export default function Historie({ from: _from = new Date(Date.now() - 1000 * 60 * 60 * 16), to: _to = new Date(_from.getTime() + 1000 * 60 * 60 * 24), title = "Historie", dummyUsageData }: { from?: Date; to?: Date; title?: string; dummyUsageData?: ElviaUsage }): JSX.Element {
  const from = new Date(_from);
  const to = new Date(_to);
  from.setMinutes(0, 0, 0);
  to.setMinutes(0, 0, 0);

  const [usage, setUsage] = useState<ElviaUsage | null>(dummyUsageData ?? null);
  const [usageLastWeek, setUsageLastWeek] = useState<ElviaUsage | null>(usage);
  const [costs, setCosts] = useState<VGCost | null>(null);
  const [costsTomorrow, setCostsTomorrow] = useState<VGCost | null>(null);
  const [loading, setLoading] = useState(!dummyUsageData);

  if (!dummyUsageData) {
    useEffect(() => {
      void Promise.all([
        axios.get(`/api/usage?from=${from.getTime()}&to=${to.getTime()}`).then(res => {
          setUsage(res.data as ElviaUsage);
        }),
        axios.get(`/api/usage?from=${new Date(from.getTime() - 1000 * 60 * 60 * 24 * 7).getTime()}&to=${new Date(to.getTime() - 1000 * 60 * 60 * 24 * 7).getTime()}`).then(res2 => {
          setUsageLastWeek(res2.data as ElviaUsage);
        }),
        [from.getDate(), new Date(to.getTime() - 1).getDate()].includes(new Date().getDate()) && axios.get("/api/cost?time=today").then(res3 => {
          setCosts(res3.data as VGCost);
        }),
        [from.getDate(), new Date(to.getTime() - 1).getDate()].includes(new Date(new Date().getTime() + 1000 * 60 * 60 * 24).getDate()) && axios.get("/api/cost?time=latest").then(res4 => {
          const data = res4.data as VGCost;
          if (data.date.endsWith(String(new Date(new Date().getTime() + 1000 * 60 * 60 * 24).getDate()).padStart(2, "0"))) {
            setCostsTomorrow(data);
          }
        }),
      ]).then(() => setLoading(false));
    }, []);
  }

  return loading ?
    <>
      <h1>Laster...</h1>
    </> :
    <>
      <div className="my-20 w-full">
        <h1 className="my-6 text-center text-7xl">{title}</h1>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              backgroundColor: "transparent",
              type: "column",
              zoomType: "x",
            },
            credits: { enabled: false },
            labels: {
              style: {
                color: "#ffffff",
              },
            },
            legend: {
              itemStyle: { "text-decoration": "none", "color": "white" },
              itemHiddenStyle: { "text-decoration": "line-through", "color": "gray" },
              layout: "vertical",
            },
            plotOptions: {
              series: {
                grouping: false,
                borderWidth: 0,
                borderRadius: 5,
              },
            },
            title: { text: null },
            tooltip: {
              shared: true,
              valueDecimals: 2,
            },
            series: [
              usage?.length && {
                name: "Total pris",
                data: usage.map(({ startTime, value }) => {
                  const date = new Date(startTime);
                  const cost = costs?.prices[date.getHours()];
                  return {
                    y: cost ? value * cost : null,
                  };
                }),
                color: "#185a36",
                tooltip: { valueSuffix: " øre" },
                type: "line",
                marker: { enabled: false },
                dashStyle: "ShortDash",
                yAxis: 1,
                zIndex: 3,
              },
              usage?.length && {
                name: "Siste 24 timene",
                data: usage.map(({ value }) => ({ y: value })),
                color: "#28965A",
                tooltip: { valueSuffix: " kWh" },
                zIndex: 1,
              },
              usageLastWeek?.length && {
                name: "Forrige uke",
                data: usageLastWeek.map(({ value }) => ({ y: value })),
                color: "#999999",
                tooltip: { valueSuffix: " kWh" },
                pointPlacement: -0.2,
                zIndex: 0,
              },
              usageLastWeek?.length && {
                name: "Spotpris",
                data: usageLastWeek.map(({ startTime }) => {
                  const date = new Date(startTime);

                  let value: number | null = null;
                  if (new Date().getDay() === date.getDay()) value = costs?.prices[date.getHours()] ?? null;
                  else if (new Date().getDay() + 1 === date.getDay()) value = costsTomorrow?.prices[date.getHours()] ?? null;

                  return { y: value };
                }),
                color: "#FFC107",
                tooltip: { valueSuffix: " øre/kWh" },
                type: "line",
                marker: { enabled: false },
                dashStyle: "ShortDash",
                yAxis: 1,
                zIndex: 2,
              },
            ].filter(Boolean),
            xAxis: {
              categories: usageLastWeek?.map(({ startTime }) => String(new Date(startTime).getHours()).padStart(2, "0")),
              labels: {
                style: {
                  color: "#ffffff",
                },
              },
              lineColor: "#ffffff",
              tickInterval: 1,
              title: {
                text: "Time",
                style: {
                  color: "#ffffff",
                },
              },
            },
            yAxis: [
              {
                labels: {
                  format: "{value} kWh",
                  style: {
                    color: "#ffffff",
                  },
                },
                lineColor: "#ffffff",
                tickInterval: 1,
                title: {
                  text: "Kilowatt",
                  style: {
                    color: "#ffffff",
                  },
                },
              },
              {
                labels: {
                  format: "{value} øre",
                  style: {
                    color: "#ffffff",
                  },
                },
                lineColor: "#ffffff",
                opposite: true,
                title: {
                  text: "Pris",
                  style: {
                    color: "#ffffff",
                  },
                },
              },
            ],
          }}
        />
      </div>
    </>;
}
