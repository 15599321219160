import React, { useEffect, useState } from "react";
import Historie from "../components/Historie";
import Toppene from "../components/Toppene";

export default function Home(): JSX.Element {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 600_000);
    return () => clearInterval(interval);
  }, []);

  const today = new Date(date);
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const inTwoDays = new Date(tomorrow);
  inTwoDays.setDate(inTwoDays.getDate() + 1);

  return (
    <div>
      <Toppene key={`toppene-${date.getTime()}`}/>
      <Historie key={`historie-${date.getTime()}`} from={today} to={tomorrow} title="I dag"/>
      <Historie key={`historie2-${date.getTime()}`} from={tomorrow} to={inTwoDays} title="I morgen"/>
      <p className="bottom-2 right-2">Sist oppdatert: {date.toLocaleTimeString("nb-NO")}</p>
    </div>
  );
}
