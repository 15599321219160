import Historie from "../components/Historie";
import React from "react";
import Toppene from "../components/Toppene";

export default function Home(): JSX.Element {
  return (
    <div>
      <Toppene />
      <Historie />
    </div>
  );
}
