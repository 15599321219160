import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageDemo from "./pages/Demo";
import PageHome from "./pages/Home";
import PageInfoscreen from "./pages/Infoscreen";
import PageLayout from "./components/Layout";
import PageNotFound from "./pages/NotFound";
import React from "react";
import ReactDOM from "react-dom/client";
import { unregister as registerServiceWorker } from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route index element={<PageHome />} />
        <Route path="infoskjerm" element={<PageInfoscreen />} />
        <Route path="demo" element={<PageDemo />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>,
);


registerServiceWorker();
